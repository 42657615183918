import { useTranslation } from 'react-i18next';
import TodosCamposGerarSubmeterDocs from './TodosCamposGerarSubmeterDocs';
import StatusCompletedDocumentos from './StatusDocumentos.js/StatusCompletedDocumentos';
import StatusValidationDocumentos from './StatusDocumentos.js/StatusValidationDocumentos';
import StatusRejectedDocumentos from './StatusDocumentos.js/StatusRejectedDocumentos';


export default function GestaoStatusDocumentos({
    data,
    setData,
    campos,
    estado,
    isDarkTheme,
    parametros,
}) {



    const { t } = useTranslation();

    return (
        <>
            {data.status == "completed" ? (
                <StatusCompletedDocumentos

                />
            ) : data.status == "pending" ? (
                <TodosCamposGerarSubmeterDocs
                    data={data}
                    setData={setData}
                    estado={estado}
                    campos={campos}
                    isDarkTheme={isDarkTheme}
                    parametros={parametros}
                />
            ) : data.status == "validation" ? (
                <StatusValidationDocumentos

                />
            ) : data.status == "rejected" ? (
                <StatusRejectedDocumentos
                    data={data}
                    setData={setData}
                />
            ) : null}
        </>
    );
}
